import React, { useCallback, useState } from "react";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import AssistanceAnalysisIndividual from "./individual";
import AssistanceAnalysisTotals from "./totals";
import { useSelector } from "react-redux";
import { getSpecialProcessParameters } from "../selectors";
import { getAssistanceAnalysisIndividualAction } from "../actions";
import { useDispatch } from "react-redux";

const GridContainer = ({ calendar, isHistorical, onGoBackToCalendarSelection, onClickGetDashboardData }) => {

  const [dateFrom, setDateFrom] = useState(createDateFromDDMMYYYY(calendar.from));
  const [dateTo, setDateTo] = useState(createDateFromDDMMYYYY(calendar.to));
  const [employee, setEmployee] = useState(null);

  const dispatch = useDispatch();
  const specialProcessParameters = useSelector(getSpecialProcessParameters);

  const fetchIndividualGridData = useCallback(() => {
    dispatch(getAssistanceAnalysisIndividualAction(formatDate(dateFrom), formatDate(dateTo), isHistorical, employee['Código de empleado']));
  }, [dispatch, dateFrom, dateTo, isHistorical, employee])

  return (
    <>
      {
        !Boolean(employee) ?
          <AssistanceAnalysisTotals
            dateFrom={dateFrom}
            dateTo={dateTo}
            isHistorical={isHistorical}
            onGoBack={onGoBackToCalendarSelection}
            calendar={calendar}
            processByPathParameter={calendar.processByPathParameter}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
            setEmployee={setEmployee}
            specialProcessParameters={specialProcessParameters}
            onClickGetDashboardData={onClickGetDashboardData}
          />
          :
          <AssistanceAnalysisIndividual
            isHistorical={isHistorical}
            employee={employee}
            calendarName={calendar.value}
            processByPathParameter={calendar.processByPathParameter}
            specialProcessParameters={specialProcessParameters}
            onGoBack={() => setEmployee(null)}
            fetchIndividualGridData={fetchIndividualGridData}
          />
      }
    </>
  );
}

export default GridContainer;
