import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
    timeFormatter,
    timeValueGetter,
} from "@icarius-table/date";
import {
    numberComparatorMax2,
    numberFilterParams,
    numberFormatterMin2,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Código de empleado",
            field: "Código de empleado",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Apellido y nombres",
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Observaciones",
            field: "Observaciones",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Marcas inconsistentes",
            field: "Marcas inconsistentes",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Atraso en la entrada",
            field: "Atraso en la entrada",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Demora en el break",
            field: "Demora en el break",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Retiro anticipado",
            field: "Retiro anticipado",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Ausencias no justificadas",
            field: "Ausencias no justificadas",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Trabajo en día no programado",
            field: "Trabajo en día no programado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Horas extras sin autorizar",
            field: "Horas extras sin autorizar",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Día con cambio de horario",
            field: "Día con cambio de horario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Fecha",
            field: "Fecha",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Día",
            field: "Día",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Horario",
            field: "Horario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Entrada",
            field: "Entrada",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Salida al break",
            field: "Salida al break",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Regreso del break",
            field: "Regreso del break",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Salida",
            field: "Salida",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Horas turno",
            field: "Horas turno",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Marcas de asistencia",
            field: "Marcas de asistencia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Horas de permanencia",
            field: "Horas de permanencia",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Antes",
            field: "Antes",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "No imputable",
            field: "No imputable",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Normal",
            field: "Normal",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Después",
            field: "Después",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Total extras",
            field: "Total extras",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Extras autorizadas",
            field: "Extras autorizadas",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Extras sin autorizar",
            field: "Extras sin autorizar",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Horas ausencia",
            field: "Horas ausencia",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysisIndividual],
        config: {
            headerName: "Comentarios",
            field: "Comentarios",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Código de empleado",
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
            pinned: "left",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Apellido y nombres",
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
            pinned: "left",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Turno",
            field: "Turno",
            filter: "agSetColumnFilter",
            pinned: "left",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Observaciones",
            field: "Observaciones",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Registros",
            field: "Registros",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Marcas inconsistentes",
            field: "Marcas inconsistentes",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Atrasos en la entrada",
            field: "Atrasos en la entrada",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Demora en el break",
            field: "Demora en el break",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Retiro anticipado",
            field: "Retiro anticipado",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Ausencias sin justificar",
            field: "Ausencias sin justificar",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Horas normales",
            field: "Horas normales",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Total de horas extras",
            field: "Total de horas extras",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Horas extras autorizadas",
            field: "Horas extras autorizadas",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Horas extras sin autorizar",
            field: "Horas extras sin autorizar",
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Trabajo en días no programados",
            field: "Trabajo en días no programados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Feriados",
            field: "Feriados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Días con cambio de horario",
            field: "Días con cambio de horario",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Lugar de trabajo",
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Obra o faena",
            field: "Obra o faena",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Tipo de calendario",
            field: "Tipo de calendario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Tipo de control horario",
            field: "Tipo de control horario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Tipo de jornada",
            field: "Tipo de jornada",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Horas extras liberadas",
            field: "Horas extras liberadas",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.assistanceAnalysis],
        config: {
            headerName: "Código de tarjeta/credencial",
            field: "Código de tarjeta/credencial",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Código de tercero',
            field: 'Código de tercero',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Nombre del tercero',
            field: 'Nombre del tercero',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Clasificación',
            field: 'Clasificación',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Apellidos y nombres',
            field: 'Apellidos y nombres',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Forma de pago',
            field: 'Forma de pago',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Banco',
            field: 'Banco',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Cuenta bancaria',
            field: 'Cuenta bancaria',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Tipo de pago',
            field: 'Tipo de pago',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Valor a pagar',
            field: 'Valor a pagar',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardThirdPaymentParty'],
        config: {
            headerName: 'Comentarios',
            field: 'Comentarios',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantity"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: "Fecha de contratación",
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: "Fecha de egreso",
            field: "Fecha de egreso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: "Turno",
            field: "Turno",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: "Horario",
            field: "Horario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ['assistanceAnalyisisDashboardCompanyCost'],
        config: {
            headerName: 'Obra o Faena',
            field: "Obra o Faena",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: "Centro de costo",
            field: "Centro de costo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: "Total de Haberes",
            field: "Total de Haberes",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: "Total aportes del empleador",
            field: "Total aportes del empleador",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCompanyCost"],
        config: {
            headerName: "Total costo empresa",
            field: "Total costo empresa",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de Haberes (Brutos)",
            field: "Total de Haberes (Brutos)",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de haberes Imponibles",
            field: "Total de haberes Imponibles",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de leyes sociales",
            field: "Total de leyes sociales",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de haberes tributables",
            field: "Total de haberes tributables",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total Impuesto",
            field: "Total Impuesto",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de haberes exentos",
            field: "Total de haberes exentos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de descuentos",
            field: "Total de descuentos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de descuentos legales",
            field: "Total de descuentos legales",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de descuentos varios",
            field: "Total de descuentos varios",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total Líquidos/netos",
            field: "Total Líquidos/netos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total aportes del empleador (Aportes patronales)",
            field: "Total aportes del empleador (Aportes patronales)",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total de beneficios corporativos (Beneficios empresariales)",
            field: "Total de beneficios corporativos (Beneficios empresariales)",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total costo empresa estándar",
            field: "Total costo empresa estándar",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardGlobalValues"],
        config: {
            headerName: "Total costo empresa con beneficios corporativos",
            field: "Total costo empresa con beneficios corporativos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: "Haberes con aportes",
            field: "Haberes con aportes",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: "Descuentos legales",
            field: "Descuentos legales",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: "Haberes sin aporte",
            field: "Haberes sin aporte",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: "Descuentos varios",
            field: "Descuentos varios",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardAnalytics"],
        config: {
            headerName: "Líquido/Neto",
            field: "Líquido/Neto",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardBankDistribution"],
        config: {
            headerName: "Líquido/Neto",
            field: "Liquido/Neto",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: "Referencia",
            field: "Referencia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardCloud"],
        config: {
            headerName: "Valores/Ocurrencias",
            field: "Valores/Ocurrencias",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config:
        {
            headerName: "Proceso de cálculo",
            field: "Proceso de cálculo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: "Fecha de contratación",
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: "Fecha de egreso",
            field: "Fecha de egreso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: "Turno",
            field: "Turno",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: "Horario",
            field: "Horario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: "Sección",
            field: "Sección",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "Sindicato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: "Institución de Previsión",
            field: "Institución de Previsión",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["assistanceAnalyisisDashboardConceptGroups"],
        config: {
            headerName: "Institución de Salud",
            field: "Institución de Salud",
            filter: "agSetColumnFilter",
        }
    },
]