import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

export const GET_TOTALS = NAME + "/GET_TOTALS";
export const GET_TOTALS_FULFILLED = NAME + "/GET_TOTALS_FULFILLED";
export const GET_TOTALS_REJECTED = NAME + "/GET_TOTALS_REJECTED";

export const GET_INDIVIDUAL = NAME + "/GET_INDIVIDUAL";
export const GET_INDIVIDUAL_FULFILLED = NAME + "/GET_INDIVIDUAL_FULFILLED";
export const GET_INDIVIDUAL_REJECTED = NAME + "/GET_INDIVIDUAL_REJECTED";

export const EXECUTE = NAME + "/EXECUTE";
export const EXECUTE_REJECTED = NAME + "/EXECUTE_REJECTED";
export const EXECUTE_FULFILLED = NAME + "/EXECUTE_FULFILLED";

export const UPDATE = NAME + "/UPDATE";
export const UPDATE_REJECTED = NAME + "/UPDATE_REJECTED";
export const UPDATE_FULFILLED = NAME + "/UPDATE_FULFILLED";

export const GET_CHANGE_SCHEDULE = NAME + "/GET_CHANGE_SCHEDULE";
export const GET_CHANGE_SCHEDULE_REJECTED = NAME + "/GET_CHANGE_SCHEDULE_REJECTED";
export const GET_CHANGE_SCHEDULE_FULFILLED = NAME + "/GET_CHANGE_SCHEDULE_FULFILLED";

export const RESET_STATE_DASHBOARD = NAME + "/RESET_STATE_DASHBOARD";

export const GET_DASHBOARD = NAME + "/GET_DASHBOARD";
export const GET_DASHBOARD_FULFILLED = NAME + "/GET_DASHBOARD_FULFILLED";
export const GET_DASHBOARD_REJECTED = NAME + "/GET_DASHBOARD_REJECTED";
