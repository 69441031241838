import {
  REQUEST_VERSION,
  REQUEST_LOGIN,
  REQUEST_LOGIN_FULFILLED,
  REQUEST_VERSION_FULFILLED,
  REQUEST_LOGIN_REJECTED,
  SELECT_SOCIETY,
  LOGOUT,
  CHANGE_PIN,
  CHANGE_PIN_FULFILLED,
  CHANGE_PIN_REJECTED,
  RESET_PASSWORD,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_FULFILLED_REJECTED,
  SET_TIME,
} from "./actionTypes";

const initialState = {
  requestingLogin: false,
  requestingVersion: false,
  version: null,
  loginResponse: null,
  societySelected: null,
  changePin: false,
  resetPasswordIsLoading: false,
  unixTimestamp: "",
  time: new Date(),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LOGIN:
      return { ...state, requestingLogin: true };
    case REQUEST_LOGIN_FULFILLED:
      return {
        ...state,
        requestingLogin: false,
        loginResponse: action.payload,
      };
    case REQUEST_LOGIN_REJECTED:
      return { ...state, requestingLogin: false };

    case REQUEST_VERSION:
      return { ...state, requestingVersion: true };
    case REQUEST_VERSION_FULFILLED:
      return {
        ...state,
        requestingVersion: false,
        version: action.payload.version,
        unixTimestamp: action.payload.unixTimestamp,
      };

    case CHANGE_PIN:
      return { ...state, changePin: true };
    case CHANGE_PIN_FULFILLED:
      return { ...state, changePin: false };
    case CHANGE_PIN_REJECTED:
      return { ...state, changePin: false };

    case RESET_PASSWORD:
      return { ...state, resetPasswordIsLoading: true };
    case RESET_PASSWORD_FULFILLED:
      return { ...state, resetPasswordIsLoading: false };
    case RESET_PASSWORD_FULFILLED_REJECTED:
      return { ...state, resetPasswordIsLoading: false };

    case SELECT_SOCIETY: {
      return { ...state, societySelected: action.payload };
    }
    case LOGOUT:
      return { ...initialState, loginResponse: null };

    case SET_TIME:
      const newTime = state.time + 1000;
      return { ...state, time: action.payload || newTime };
    default:
      return state;
  }
}
