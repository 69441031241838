import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import {
  timeFormatter,
  timeValueGetter,
} from "@icarius-table/date";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import SetParamDialog from "@icarius-common/setParamDialog";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import { useDispatch } from "react-redux";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getIsLoading, getIndividualData, getAbsenceTypeList } from "../selectors";
import useIndividualDialogs from "./dialogs/useIndividualDialogs";
import UpdateObservedDialog from "./dialogs/updateObservedDialog";
import ExtraHoursAuthDialog from "./dialogs/extraHoursAuthDialog";
import ChangeScheduleDialog from "./dialogs/changeScheduleDialog";
import useProcessExecution from "./useProcessExecution";
import JustifyAbsenceDialog from "./dialogs/justifyAbsenceDialog";

const OBSERVATION_COLUMNS = [
  "Observaciones",
  "Marcas inconsistentes",
  "Atraso en la entrada",
  "Demora en el break",
  "Retiro anticipado",
  "Trabajo en día no programado",
  "Horas extras sin autorizar",
  "Día con cambio de horario",
  "Ausencias no justificadas",
];

const AssistanceAnalysisIndividual = (props) => {

  const {
    isHistorical,
    employee,
    calendarName,
    processByPathParameter,
    specialProcessParameters,
    onGoBack,
    fetchIndividualGridData,
  } = props;

  const dispatch = useDispatch();
  const defaultParameter = [{ code: 1, value: processByPathParameter }];

  const [showColumns, setShowColumns] = useState(true);

  const {
    menuAnchor,
    setMenuAnchor,
    state,
    handlers,
  } = useIndividualDialogs();

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('ASSISTANCE_ANALYSIS', false, () => setShowColumns(true));

  useEffect(() => {
    fetchIndividualGridData();
  }, [fetchIndividualGridData])

  const {
    processExecutionData,
    processExecutionFunctions,
  } = useProcessExecution(false, specialProcessParameters, defaultParameter);

  const handleFetchAfterSubmit = () => {
    handlers.handleSetHasChangedData();
    fetchIndividualGridData();
  }

  const handleGoBack = () => {
    if (state.hasChangedData) {
      dispatch(openDialogAction({
        title: "Atención",
        msg: (
          <>
            Considere que los cambios aquí aplicados, tales como:
            <br />
            - Completitud o modificaciones en las marcas de asistencia
            <br />
            - Cambios de horarios
            <br />
            - Autorización de horas extras
            <br />
            - Justificación de ausencias
            <br />
            No se verán reflejados en los resultados del análisis de asistencia hasta que se ejecute nuevamente el proceso de “Cálculo de análisis de asistencia”
          </>
        ),
        onConfirm: () => onGoBack(),
        onCancel: () => onGoBack(),
        acceptOnly: true,
        maxWidth: "md",
      }));
      return;
    }

    onGoBack();
  }

  const isLoading = useSelector(getIsLoading);
  const data = useSelector(getIndividualData);
  const absenceTypeList = useSelector(getAbsenceTypeList);
  const exportingGridStatus = useSelector(getIsExportingGrid);

  const ownColumnDef = useMemo(() => {
    let columnDefinitions = getColumnDefByPage(paths.assistanceAnalysisIndividual);
    const dynamicColumns = [];

    if (data.length > 0) {
      Object.keys(data[0]).forEach((key) => {
        const arrayToIgnore = columnDefinitions.map(item => item.field);

        if (!arrayToIgnore.includes(key)) {
          let item = {
            headerName: key,
            field: key,
            filter: "agSetColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
          };

          dynamicColumns.push(item);
        }
      });

      columnDefinitions.splice(columnDefinitions.length - 3, 0, ...dynamicColumns);
    }

    columnDefinitions = columnDefinitions.map((item) => {
      return ({
        ...item, hide: !showColumns && OBSERVATION_COLUMNS.includes(item.headerName)
      })
    });

    return columnDefinitions;
  }, [data, showColumns])

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const processButton = (gridRef) => (
    Boolean(specialProcessParameters) && !isHistorical &&
    <CustomIconButton
      title={'Calcular análisis de asistencia'}
      onClick={() => processExecutionFunctions.handleValidateAndAskProcess(gridRef)}
      type={"execute"}
    />
  )

  const actionsItem = (gridRef) => (
    !isHistorical &&
    <>
      <CustomIconButton
        title={'Acciones'}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        <MoreVertIcon />
      </CustomIconButton>
      <StyledHelpMenu
        anchorEl={menuAnchor}
        isOpen={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Autorizar horas extra'}
          onClick={() => handlers.handleOpenExtraHoursDialog(gridRef)}
        />
        <MenuItemWithIcon
          text={'Cambiar horarios'}
          onClick={() => handlers.handleOpenChangeScheduleDialog(gridRef)}
        />
        <MenuItemWithIcon
          text={'Justificar ausencia'}
          onClick={() => handlers.handleOpenJustifyAbsenceDialog(gridRef)}
        />
        <MenuItemWithIcon
          text={'Marcar registros como observados (Para revisión posterior)'}
          onClick={() => handlers.handleOpenMarkAsObservedDialog(gridRef)}
        />
        <MenuItemWithIcon
          text={'Quitar observación de los registros (Ya fueron revisados)'}
          onClick={() => handlers.handleOpenUnmarkAsObservedDialog(gridRef)}
        />
      </StyledHelpMenu>
    </>
  )


  const selectInput = (gridRef) => (
    <>
      <TemplatesHeader
        gridRef={gridRef}
        user={user}
        templates={templates}
        selectedTemplate={selectedTemplate}
        getRole={getRole}
        handleImportTemplates={handleImportTemplates}
        handleExportTemplates={handleExportTemplates}
        handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
        handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
        handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
        handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
      />
    </>
  )

  const child = (gridRef) =>
    <>
      {
        processExecutionData.paramDialogIsOpen &&
        <SetParamDialog
          open={processExecutionData.paramDialogIsOpen}
          params={processExecutionData.paramsToUse}
          handleClose={processExecutionFunctions.handleCloseParamDialog}
          handleAgree={processExecutionFunctions.executeProcess}
        />
      }
      {
        (state.markAsObservedDialogIsOpen || state.unmarkAsObservedDialogIsOpen) &&
        <UpdateObservedDialog
          open={(state.markAsObservedDialogIsOpen || state.unmarkAsObservedDialogIsOpen)}
          isMark={state.markAsObservedDialogIsOpen}
          code={state.selectedData.code}
          dates={state.selectedData.dates}
          isLoading={isLoading}
          handleFetchAfterSubmit={handleFetchAfterSubmit}
          handleClose={state.markAsObservedDialogIsOpen ? handlers.handleCloseMarkAsObservedDialog : handlers.handleCloseUnmarkAsObservedDialog}
        />
      }
      {
        state.extraHoursDialogIsOpen &&
        <ExtraHoursAuthDialog
          open={state.extraHoursDialogIsOpen}
          day={state.selectedData.day}
          code={state.selectedData.code}
          date={state.selectedData.date}
          extraHoursTotal={state.selectedData.extraHoursTotal}
          noAuthExtraHours={state.selectedData.noAuthExtraHours}
          isLoading={isLoading}
          handleFetchAfterSubmit={handleFetchAfterSubmit}
          handleClose={handlers.handleCloseExtraHoursDialog}
        />
      }
      {
        state.justifyAbsenceDialogIsOpen &&
        <JustifyAbsenceDialog
          open={state.justifyAbsenceDialogIsOpen}
          day={state.selectedData.day}
          code={state.selectedData.code}
          date={state.selectedData.date}
          absenceHours={state.selectedData.absenceHours}
          scheduleHours={state.selectedData.scheduleHours}
          absenceTypeList={absenceTypeList}
          isLoading={isLoading}
          handleFetchAfterSubmit={handleFetchAfterSubmit}
          handleClose={handlers.handleCloseJustifyAbsenceDialog}
        />
      }
      {
        state.changeScheduleDialogIsOpen &&
        <ChangeScheduleDialog
          open={state.changeScheduleDialogIsOpen}
          day={state.selectedData.day}
          code={state.selectedData.code}
          date={state.selectedData.date}
          schedule={state.selectedData.schedule}
          isLoading={isLoading}
          handleFetchAfterSubmit={handleFetchAfterSubmit}
          handleClose={handlers.handleCloseChangeScheduleDialog}
        />
      }
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>

  return (
    <CommonPage
      rowData={data}
      title={`Análisis de asistencia del colaborador - ${calendarName}`}
      gridTitle={`${employee['Código de empleado']}_${employee['Apellido y nombres']} - ${calendarName}`}
      columnDefPage={paths.assistanceAnalysisIndividual}
      ownColumnDef={ownColumnDef}
      isLoading={isLoading || exportingGridStatus || isLoadingTemplates}
      menuItems={[goBackItem, actionsItem, processButton]}
      customHeader={selectInput}
      hasHelp
      hasExpand
      hasSelectAll
      hasSwitch={!Boolean(selectedTemplate)}
      handleSwitchClick={() => setShowColumns(prev => !prev)}
      switchLabelOff={'Ver observaciones'}
      switchLabelOn={'Ver observaciones'}
      switchValue={showColumns}
      pathToUseForProcess={paths.assistanceAnalysisIndividual.substring(1)}
      processByPathParameter={defaultParameter}
    >
      {child}
    </CommonPage>
  );
}

export default AssistanceAnalysisIndividual;