import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  assistanceRows: [],
  mapData: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ASSISTANCE_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ASSISTANCE_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        assistanceRows: action.payload.assistanceRows,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_ASSISTANCE_ROWS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.COMPLETE_ASSISTANCE_MARK:
      return { ...state, isLoading: true };
    case actionTypes.COMPLETE_ASSISTANCE_MARK_FULFILLED:
      return {
        ...state,
        isLoading: false,
        assistanceRows: action.payload.assistanceRows,
      };
    case actionTypes.COMPLETE_ASSISTANCE_MARK_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_MAP_DATA:
      return { ...state, isLoading: true };
    case actionTypes.GET_MAP_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        mapData: action.payload.data,
      };
    case actionTypes.GET_MAP_DATA_REJECTED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
