import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { getChangeScheduleAssistanceAnalysisIndividualAction } from "../../actions";

const useIndividualDialogs = () => {

  const dispatch = useDispatch();

  const [hasChangedData, setHasChangedData] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [markAsObservedDialogIsOpen, setMarkAsObservedDialogIsOpen] = useState(false);
  const [unmarkAsObservedDialogIsOpen, setUnmarkAsObservedDialogIsOpen] = useState(false);
  const [changeScheduleDialogIsOpen, setChangeScheduleDialogIsOpen] = useState(false);
  const [extraHoursDialogIsOpen, setExtraHoursDialogIsOpen] = useState(false);
  const [justifyAbsenceDialogIsOpen, setJustifyAbsenceDialogIsOpen] = useState(false);

  const handleOpenGenericDialog = (individual, gridRef, callback) => {
    const rows = gridRef.api.getSelectedRows();
    if (individual) {
      if (rows.length !== 1) {
        dispatch(openSnackbarAction({ msg: 'Debe seleccionar un único registro', severity: "warning" }));
        return;
      }
    } else {
      if (rows.length < 1) {
        dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
        return;
      }
    }

    let newSelectedData = {
      code: rows[0]['Código de empleado'],
      schedule: rows[0]['Horario'], // para el modal de cambio de horarios y completar marcas
      entry: rows[0]['Entrada'], // para el modal de completar marcas
      exit: rows[0]['Salida'], // para el modal de completar marcas
      exitToBreak: rows[0]['Salida al break'], // para el modal de completar marcas
      returnFromBreak: rows[0]['Regreso del break'], // para el modal de completar marcas
      extraHoursTotal: rows[0]['Total extras'], // para el modal de autorizacion de horas extra
      noAuthExtraHours: rows[0]['Extras sin autorizar'], // para el modal de autorizacion de horas extra
      absenceHours: rows[0]['Horas ausencia'], // para el modal de justificar ausencias
      scheduleHours: rows[0]['Horas turno'], // para el modal de justificar ausencias
      day: rows[0]['Día'], // para el modal de autorizacion de horas extra
    }

    if (individual) {
      newSelectedData.date = formatDateYYYYMMDD(createDateFromDDMMYYYY(rows[0]['Fecha']));
    } else {
      newSelectedData.dates = rows.map(item => formatDateYYYYMMDD(createDateFromDDMMYYYY(item['Fecha'])));
    }

    setMenuAnchor(null);
    setSelectedData(newSelectedData);
    callback(rows, newSelectedData);
  }

  const handleOpenExtraHoursDialog = (gridRef) => {
    handleOpenGenericDialog(
      true,
      gridRef,
      (rows) => {
        if (rows.some(item => item['Horas extras sin autorizar'] === 'No')) {
          dispatch(openSnackbarAction({ msg: 'El registro debe tener horas extras sin autorizar', severity: "warning" }));
          return;
        }

        setExtraHoursDialogIsOpen(true);
      }
    );
  }

  const handleOpenJustifyAbsenceDialog = (gridRef) => {
    handleOpenGenericDialog(
      true,
      gridRef,
      (rows) => {
        if (rows.some(item => item['Ausencias no justificadas'] !== "Si")) {
          dispatch(openSnackbarAction({ msg: 'El registro debe tener ausencias no justificadas', severity: "warning" }));
          return;
        }

        setJustifyAbsenceDialogIsOpen(true);
      }
    );
  }

  const handleOpenChangeScheduleDialog = (gridRef) => {
    handleOpenGenericDialog(
      true,
      gridRef,
      (_, data) => {
        dispatch(getChangeScheduleAssistanceAnalysisIndividualAction(data.code, data.date))
          .then((resp) => {
            if (resp.status === 200) {
              setChangeScheduleDialogIsOpen(true);
            }
          });
      }
    );
  }

  const handleOpenMarkAsObservedDialog = (gridRef) => {
    handleOpenGenericDialog(
      false,
      gridRef,
      () => setMarkAsObservedDialogIsOpen(true)
    );
  }


  const handleOpenUnmarkAsObservedDialog = (gridRef) => {
    handleOpenGenericDialog(
      false,
      gridRef,
      () => setUnmarkAsObservedDialogIsOpen(true)
    );
  }

  const handleCloseExtraHoursDialog = () => {
    setExtraHoursDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleCloseChangeScheduleDialog = () => {
    setChangeScheduleDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleCloseMarkAsObservedDialog = () => {
    setMarkAsObservedDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleCloseUnmarkAsObservedDialog = () => {
    setUnmarkAsObservedDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleCloseJustifyAbsenceDialog = () => {
    setJustifyAbsenceDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleSetHasChangedData = () => {
    setHasChangedData(true);
  }

  const state = {
    selectedData,
    markAsObservedDialogIsOpen,
    unmarkAsObservedDialogIsOpen,
    changeScheduleDialogIsOpen,
    extraHoursDialogIsOpen,
    justifyAbsenceDialogIsOpen,
    hasChangedData,
  }

  const handlers = {
    handleOpenExtraHoursDialog,
    handleOpenChangeScheduleDialog,
    handleOpenMarkAsObservedDialog,
    handleOpenUnmarkAsObservedDialog,
    handleOpenJustifyAbsenceDialog,
    handleCloseExtraHoursDialog,
    handleCloseChangeScheduleDialog,
    handleCloseMarkAsObservedDialog,
    handleCloseUnmarkAsObservedDialog,
    handleCloseJustifyAbsenceDialog,
    handleSetHasChangedData,
  }

  return {
    menuAnchor,
    setMenuAnchor,
    state,
    handlers,
  }
}

export default useIndividualDialogs;
