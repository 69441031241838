import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import SetParamDialog from "@icarius-common/setParamDialog";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { getIsExportingGrid } from "src/app/selectors";
import { getAssistanceAnalysisTotalsAction } from "../actions";
import { getIsLoading, getTotalData } from "../selectors";
import useProcessExecution from "./useProcessExecution";

const AssistanceAnalysisTotals = (props) => {

  const {
    calendar,
    isHistorical,
    onGoBack,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    setEmployee,
    processByPathParameter,
    specialProcessParameters,
    onClickGetDashboardData,
  } = props;

  const [showFiltered, setShowFiltered] = useState(true);

  const defaultParameter = [{ code: 1, value: processByPathParameter }];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssistanceAnalysisTotalsAction(formatDate(dateFrom), formatDate(dateTo), isHistorical));
  }, [dispatch, dateFrom, dateTo, isHistorical])

  const {
    processExecutionData,
    processExecutionFunctions,
  } = useProcessExecution(true, specialProcessParameters, defaultParameter);

  const isLoading = useSelector(getIsLoading);
  const data = useSelector(getTotalData);
  const exportingGridStatus = useSelector(getIsExportingGrid);

  const handleRowClick = (row) => {
    setEmployee(row.data);
  }

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={onGoBack}
      type={"goBack"}
    />

  const processButton = (gridRef) => (
    Boolean(specialProcessParameters) && !isHistorical &&
    <CustomIconButton
      title={'Calcular análisis de asistencia'}
      onClick={() => processExecutionFunctions.handleValidateAndAskProcess(gridRef)}
      type={"execute"}
    />
  )

  const showDashboardItem = () => {
    return (
      <CustomIconButton
        title={"Tablero de control"}
        onClick={() => onClickGetDashboardData()}
        type={"graphics"}
      />
    )
  }

  const customHeader = () =>
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <DatePicker
          style={{ marginRight: 10 }}
          label={'Fecha desde'}
          invalidDateMessage=''
          cancelLabel={getLocalizedString("cancel")}
          okLabel={getLocalizedString("ok")}
          minDateMessage={''}
          maxDateMessage={''}
          format="dd/MM/yyyy"
          margin="none"
          value={dateFrom}
          onChange={(e) => setDateFrom(e)}
          minDate={createDateFromDDMMYYYY(calendar.from)}
          maxDate={dateTo}
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <DatePicker
          label={'Fecha hasta'}
          invalidDateMessage=''
          cancelLabel={getLocalizedString("cancel")}
          okLabel={getLocalizedString("ok")}
          minDateMessage={''}
          maxDateMessage={''}
          format="dd/MM/yyyy"
          margin="none"
          value={dateTo}
          onChange={(e) => setDateTo(e)}
          minDate={dateFrom}
          maxDate={createDateFromDDMMYYYY(calendar.to)}
        />
      </MuiPickersUtilsProvider>
    </>

  const filteredData = useMemo(() => {
    if (!showFiltered) return data;
    return data.filter((item) => item["Observaciones"] > 0);
  }, [showFiltered, data])

  return (
    <CommonPage
      rowData={filteredData}
      title={`Análisis de asistencia general - ${calendar.value}`}
      gridTitle={`Análisis de asistencia general - ${calendar.value}`}
      columnDefPage={paths.assistanceAnalysis}
      isLoading={isLoading || exportingGridStatus}
      menuItems={[goBackItem, processButton, showDashboardItem]}
      customHeader={customHeader}
      handleRowClick={handleRowClick}
      hasHelp
      hasExpand
      hasSelectAll
      hasSwitch
      handleSwitchClick={() => setShowFiltered(prev => !prev)}
      switchValue={showFiltered}
      switchLabelOff={"Todos"}
      switchLabelOn={"Con observaciones"}
      processByPathParameter={defaultParameter}
    >
      {
        processExecutionData.paramDialogIsOpen &&
        <SetParamDialog
          open={processExecutionData.paramDialogIsOpen}
          params={processExecutionData.paramsToUse}
          handleClose={processExecutionFunctions.handleCloseParamDialog}
          handleAgree={processExecutionFunctions.executeProcess}
        />
      }
    </CommonPage>
  );
}

export default AssistanceAnalysisTotals;