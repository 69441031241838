import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  createDateFromYYYYMMDD,
  formatDate,
} from "@icarius-utils/date";
import { getSchedules } from "../../selectors";
import { updateChangeScheduleAssistanceAnalysisIndividualAction } from "../../actions";

const ChangeScheduleDialog = (props) => {

  const {
    open,
    day,
    code,
    date,
    schedule,
    isLoading,
    handleClose,
    handleFetchAfterSubmit,
  } = props;

  const schedules = useSelector(getSchedules);
  const [selectedSchedule, setSelectedSchedule] = useState(() => schedules?.find(item => item.value === schedule)?.key || '');

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!selectedSchedule) {
      dispatch(openSnackbarAction({ msg: 'El campo horario es obligatorio', severity: "error" }));
      return;
    }

    const dataToSend = {
      code,
      date,
      schedule: selectedSchedule,
    }

    dispatch(updateChangeScheduleAssistanceAnalysisIndividualAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleFetchAfterSubmit();
          handleClose();
        }
      });
  }

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      fullWidth
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`Cambiar horarios: ${day} ${formatDate(createDateFromYYYYMMDD(date))}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-schedule`}>{"Horario"}</InputLabel>
                <Select
                  value={selectedSchedule}
                  labelId={`label-schedule`}
                  id={`select-schedule`}
                  onChange={(e) => setSelectedSchedule(e.target.value)}
                  margin={"none"}
                >
                  {
                    schedules.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ChangeScheduleDialog;
