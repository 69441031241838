import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import {
  createDateFromHHMM,
  getDateIsValid,
  formatDateHHMM,
  createDateFromYYYYMMDD,
  formatDate,
} from "@icarius-utils/date";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { updateJustifyAbsenceAssistanceAnalysisIndividualAction } from "../../actions";

const gridStyle = { padding: "10px" };

const JustifyAbsenceDialog = (props) => {

  const {
    open,
    day,
    code,
    date,
    absenceHours,
    scheduleHours,
    absenceTypeList,
    isLoading,
    handleClose,
    handleFetchAfterSubmit,
  } = props;

  const [type, setType] = useState("");
  const [hours, setHours] = useState(() => createDateFromHHMM("00:00"));
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const filteredAbsenceTypeList = useMemo(() => {
    // si las horas de ausencia son iguales a las del turno, se usa todo el array
    if (scheduleHours === absenceHours) return absenceTypeList;
    return absenceTypeList?.filter((item) => item.unit === "H");
  }, [absenceTypeList, scheduleHours, absenceHours]);

  const typeIsUnitHours = absenceTypeList?.find((item) => item.key === type)?.unit === "H" || false;

  const handleSubmit = () => {
    if (!type) {
      dispatch(openSnackbarAction({ msg: 'El tipo de inasistencia es obligatorio', severity: "error" }));
      return;
    }

    if (typeIsUnitHours && !getDateIsValid(hours)) {
      dispatch(openSnackbarAction({ msg: 'La hora ingresada no es válida', severity: "error" }));
      return;
    }

    const dataToSend = {
      code,
      date,
      type,
      comment,
      hours: typeIsUnitHours ? formatDateHHMM(hours) : "",
    }

    dispatch(updateJustifyAbsenceAssistanceAnalysisIndividualAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleFetchAfterSubmit();
          handleClose();
        }
      });
  }

  const handleChangeHours = (value) => {
    if (value > createDateFromHHMM(absenceHours)) {
      dispatch(openSnackbarAction({
        msg: `El valor ingresado no puede ser mayor a las horas de ausencia (${absenceHours})`,
        severity: "error",
        duration: 10000,
      }));

      value.setHours(0);
      value.setMinutes(0);
      value.setSeconds(0);
      setHours(value);
      return;
    }

    setHours(value);
  }

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      fullWidth
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`Justificar ausencia: ${day} ${formatDate(createDateFromYYYYMMDD(date))}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container item direction="column" justify="center" xs={12} style={gridStyle}>
            <Typography className="whiteText" style={{ fontSize: 12 }}>{'Horas de ausencia'}</Typography>
            <Typography className="whiteText">{absenceHours}</Typography>
          </Grid>
          <Grid container item xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-type`}>{"Tipo de inasistencia"}</InputLabel>
              <Select
                value={type}
                labelId={`label-type`}
                id={`select-type`}
                onChange={(e) => setType(e.target.value)}
                margin={"none"}
              >
                {
                  filteredAbsenceTypeList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {
            typeIsUnitHours &&
            <Grid container item xs={12} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardTimePicker
                  required
                  label={'Horas a justificar'}
                  value={hours}
                  onChange={(e) => handleChangeHours(e)}
                  invalidDateMessage=""
                  minDateMessage=""
                  minutesStep={5}
                  fullWidth
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  format="HH:mm"
                  margin="none"
                  ampm={false}
                  helperText={`Valor máximo permitido: ${absenceHours}`}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          <Grid container item xs={12} style={gridStyle}>
            <TextField
              label={'Comentarios'}
              fullWidth
              margin={"none"}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default JustifyAbsenceDialog;