import NAME from "./constants";

export const REQUEST_LOGIN = NAME + "/REQUEST_LOGIN";
export const REQUEST_LOGIN_FULFILLED = NAME + "/REQUEST_LOGIN_FULFILLED";
export const REQUEST_VERSION = NAME + "/REQUEST_VERSION";
export const REQUEST_VERSION_FULFILLED = NAME + "/REQUEST_VERSION_FULFILLED";
export const REQUEST_LOGIN_REJECTED = NAME + "/REQUEST_LOGIN_REJECTED";
export const SELECT_SOCIETY = NAME + "/SELECT_SOCIETY";
export const LOGOUT = NAME + "/LOGOUT";

export const CHANGE_PIN = NAME + "/CHANGE_PIN";
export const CHANGE_PIN_FULFILLED = NAME + "/CHANGE_PIN_FULFILLED";
export const CHANGE_PIN_REJECTED = NAME + "/CHANGE_PIN_REJECTED";

export const RESET_PASSWORD = NAME + "/RESET_PASSWORD";
export const RESET_PASSWORD_FULFILLED = NAME + "/RESET_PASSWORD_FULFILLED";
export const RESET_PASSWORD_FULFILLED_REJECTED = NAME + "/RESET_PASSWORD_FULFILLED_REJECTED";

export const SET_TIME = NAME + "/SET_TIME";