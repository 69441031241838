import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading || store[NAME].isLoadingDashboard;
export const getOpenCalendarList = store => store[NAME].openCalendarList;
export const getHistoricalCalendarList = store => store[NAME].historicalCalendarList;
export const getTotalData = store => store[NAME].totalData;
export const getIndividualData = store => store[NAME].individualData;
export const getSpecialProcessParameters = store => store[NAME].specialProcessParameters;
export const getSchedules = store => store[NAME].schedules;
export const getBreakIsEditable = store => store[NAME].breakIsEditable;
export const getAbsenceTypeList = store => store[NAME].absenceTypeList;
export const getDashboardData = store => store[NAME].dashboardData;
export const getDashboardDesc = store => store[NAME].dashboardDesc;
export const getCurrencyLocalization = store => store[NAME].currencyLocalization;