import React, { useState, memo, createRef } from "react";
import { useSelector } from "react-redux";
import {
    Quantity,
    Description,
} from "./sections";
import {
    Grid,
    Divider,
    Button,
    Typography,
} from "@material-ui/core";
import GridDialog from "./gridDialog";
import { getCurrencyLocalization } from "../../selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useScreenshot, createFileName } from "use-react-screenshot";
import CommonPage from "@icarius-common/commonPage";

const titleStyle = { marginTop: 5, fontWeight: 900, fontSize: 24, marginBottom: 5 };

const CustomExpansionPanelDetails = withStyles({
    root: {
        paddingTop: 0,
    },
})(ExpansionPanelDetails);

const CustomExpansionPanel = withStyles({
    root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        width: '100%'
    },
})(ExpansionPanel);

const CustomExpansionPanelSummary = withStyles({
    root: {
        padding: 0
    },
})(ExpansionPanelSummary);

const DashboardPage = (props) => {

    const {
        title,
        desc,
        data,
        closeDashboard,
        companyColor,
        gridTheme,
    } = props;

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [gridData, setGridData] = useState(null);
    const [gridPath, setGridPath] = useState("");

    const currencyLocalization = useSelector(getCurrencyLocalization);

    const setGridDataAndOpenDialog = (data, path) => {
        setGridData(data);
        setGridPath(path);
        setDialogIsOpen(true);
    }

    const NoIndicatorsForThisCategory = () => (
        <Grid item xs={12}>
            <Typography className="whiteText" style={{ fontSize: 20 }}>
                {"No existen indicadores en esta categoría"}
            </Typography>
        </Grid>
    )

    const LoadingContent = () => (
        <div className={"loader-center-container"} style={{ width: "10%", margin: "0 auto" }}>
            <CircularProgress style={{ color: companyColor || "#e52521" }} />
        </div>
    )

    const ref = createRef(null);

    const [, takeScreenShot] = useScreenshot({
        type: "image/png",
        quality: 1.0
    });

    const download = (image, { name = desc["Calendario de tiempos"] || "proceso", extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const goBackItem = () => (
        <CustomIconButton
            title={getLocalizedString("goBack")}
            onClick={closeDashboard}
            type={"goBack"}
        />
    )

    const screenCaptureItem = () => (
        <CustomIconButton
            title={"Descargar captura"}
            onClick={() => takeScreenShot(ref.current).then(download)}
            type={"camera"}
        />
    )

    return (
        <CommonPage
            title={title}
            menuItems={[goBackItem, screenCaptureItem]}
            isNotGridPage
        >
            <Grid container item xs={12}>
                <Grid
                    ref={ref}
                    container
                    alignItems="center"
                    style={{
                        backgroundColor: "var(--mainBackgroundColor)",
                        padding: '0px 50px',
                        marginTop: 15,
                        marginBottom: 10,
                    }}
                >
                    <Grid container alignItems="center" item xs={12} style={{ marginBottom: 10 }}>
                        <Grid item xs={11}>
                            {
                                desc &&
                                <Description desc={desc} companyColor={companyColor} />
                            }
                        </Grid>
                    </Grid>
                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Cantidades"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.quantity === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.quantity?.quantityBase?.length === 0 &&
                                            data?.quantity?.userKPIs?.length === 0 &&
                                            data?.quantity?.userGraphics?.length === 0 &&
                                            data?.quantity?.userClouds?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid item xs={12}>
                                                <Quantity
                                                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                    data={data.quantity.quantityBase}
                                                    userClouds={data.quantity.userClouds}
                                                    userGraphics={data.quantity.userGraphics}
                                                    userKPIs={data.quantity.userKPIs}
                                                    gridTheme={gridTheme}
                                                />
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>
                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel10a-content"
                            id="panel10a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Planilla de análisis de asistencia"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.conceptsGrid === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.conceptsGrid?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid container justify="center" alignItems="center" item xs={12}>
                                                <Button
                                                    style={{ width: "400px", borderRadius: "6px", height: "52px", backgroundColor: companyColor }}
                                                    disableRipple={true}
                                                    variant={"contained"}
                                                    className={"whiteText dashboardConcepts"}
                                                    onClick={() => setGridDataAndOpenDialog(data?.conceptsGrid, "conceptsGrid")}
                                                >
                                                    <Typography style={{ textTransform: "none", fontSize: "20px", fontWeight: 400 }} variant={"button"}>
                                                        {"Planilla de análisis de asistencia"}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    {
                        dialogIsOpen &&
                        <GridDialog
                            currencyLocalization={currencyLocalization}
                            calcProcessName={desc["Calendario de tiempos"]}
                            processPeriod={desc["Calendario de tiempos"]}
                            gridPath={gridPath}
                            data={gridData}
                            handleClose={() => setDialogIsOpen(false)}
                            open={dialogIsOpen}
                        />
                    }
                </Grid>
            </Grid>
        </CommonPage>
    )
};

export default memo(DashboardPage);