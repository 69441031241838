import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonPage from "@icarius-common/commonPage";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { getAppColor } from "src/app/selectors";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAssistanceAnalysisAction, getAssistanceAnalysisDashboardFromServer } from "../actions";
import { getIsLoading, getOpenCalendarList, getHistoricalCalendarList, getDashboardData } from "../selectors";
import { RESET_STATE_DASHBOARD } from "../actionTypes";
import GridContainer from "./gridContainer";
import Dashboard from "./dashboard";

const DASHBOARD_ENDPOINTS = [
  "quantity",
  "conceptsGrid",
];

const AssistanceAnalysis = () => {

  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [isHistorical, setIsHistorical] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssistanceAnalysisAction());
  }, [dispatch])

  const gridTheme = useSelector(getTheme);
  const color = useSelector(getAppColor);
  const isLoading = useSelector(getIsLoading);
  const openCalendarList = useSelector(getOpenCalendarList);
  const historicalCalendarList = useSelector(getHistoricalCalendarList);
  const dashboardData = useSelector(getDashboardData);

  const dashboardDesc = {
    "Calendario de tiempos": selectedCalendar?.name,
    "Tipo": selectedCalendar?.type,
    "Fecha desde": selectedCalendar?.from,
    "Fecha hasta": selectedCalendar?.to
  };

  const handleSelectCalendar = (calendarKey, isHistorical) => {
    const calendar = (isHistorical ? historicalCalendarList : openCalendarList).find(item => item.key === calendarKey);
    setSelectedCalendar(calendar);
    setIsHistorical(isHistorical);
  }

  const handleGoBack = () => {
    setSelectedCalendar(null);
    setIsHistorical(false);
  }

  const closeDashboard = () => {
    setShowDashboard(false);
    dispatch({ type: RESET_STATE_DASHBOARD });
  }

  const onClickGetDashboardData = () => {
    setShowDashboard(true);
    dispatch(getAssistanceAnalysisDashboardFromServer(DASHBOARD_ENDPOINTS[0], selectedCalendar.key)).then(resp =>
      resp.status && dispatch(getAssistanceAnalysisDashboardFromServer(DASHBOARD_ENDPOINTS[1], selectedCalendar.key))
    )
  }

  if (selectedCalendar && showDashboard) {
    return (
      <Dashboard
        title={`Análisis de asistencia general - ${selectedCalendar.value}`}
        closeDashboard={closeDashboard}
        data={dashboardData}
        desc={dashboardDesc}
        companyColor={color}
        gridTheme={gridTheme.theme}
      />
    )
  }

  return (
    <>
      {
        !selectedCalendar ?
          <CommonPage
            title={'Análisis de asistencia'}
            isLoading={isLoading}
            isNotGridPage
          >
            <Grid container item alignItems="center" justify="space-between" style={{ padding: "0px 24px" }} xs={12}>
              <Grid container item xs={4}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-openCalendar`}>{"Calendarios abiertos"}</InputLabel>
                  <Select
                    value={''}
                    labelId={`label-openCalendar`}
                    id={`select-openCalendar`}
                    onChange={(e) => handleSelectCalendar(e.target.value, false)}
                    margin={"none"}
                  >
                    {
                      openCalendarList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item xs={4}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-historicalCalendar`}>{"Calendarios históricos"}</InputLabel>
                  <Select
                    value={''}
                    labelId={`label-historicalCalendar`}
                    id={`select-historicalCalendar`}
                    onChange={(e) => handleSelectCalendar(e.target.value, true)}
                    margin={"none"}
                  >
                    {
                      historicalCalendarList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item xs={12} style={{ margin: "10px 0px" }}>
                <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
                  <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
                    {"Seleccione un calendario de tiempo"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CommonPage>
          :
          <GridContainer
            calendar={selectedCalendar}
            isHistorical={isHistorical}
            onGoBackToCalendarSelection={handleGoBack}
            onClickGetDashboardData={onClickGetDashboardData}
          />
      }
    </>
  );
}

export default AssistanceAnalysis;
