import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getAssistanceAnalysisAPI,
  getAssistanceAnalysisTotalsAPI,
  getAssistanceAnalysisIndividualAPI,
  executeAssistanceAnalysisTotalsAPI,
  executeAssistanceAnalysisIndividualAPI,
  updateObservedAssistanceAnalysisIndividualAPI,
  getChangeScheduleAssistanceAnalysisIndividualAPI,
  updateChangeScheduleAssistanceAnalysisIndividualAPI,
  updateExtraHoursAssistanceAnalysisIndividualAPI,
  updateJustifyAbsenceAssistanceAnalysisIndividualAPI,
  getAssistanceAnalysisDashboardFromServerAPI,
} from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  let duration = 5000;
  switch (e.response?.data?.status) {
    case "INVALID_MAX_WEEK_HOURS":
      errorString = "El cambio de horario no está permitido porque la suma de las horas de todos los horarios programados para la semana, supera la cantidad pactada contractualmente con el colaborador";
      duration = 15000;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: duration }));
}

export const getAssistanceAnalysisAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAssistanceAnalysisAPI();
    let openCalendarList = response.data && response.data.openCalendarList;
    let historicalCalendarList = response.data && response.data.historicalCalendarList;
    let specialProcessParameters = response.data && response.data.specialProcessParameters;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { openCalendarList, historicalCalendarList, specialProcessParameters },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getAssistanceAnalysisDashboardFromServer = (option, code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DASHBOARD, payload: { option: option, } });
  try {
    let response = await getAssistanceAnalysisDashboardFromServerAPI(`/${option}?calendar_code=${code}`);
    dispatch({
      type: actionTypes.GET_DASHBOARD_FULFILLED,
      payload: { option: option, data: response.data }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DASHBOARD_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getAssistanceAnalysisTotalsAction = (from, to, isHistorical) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TOTALS });
  try {
    let response = await getAssistanceAnalysisTotalsAPI(`?from=${from}&to=${to}&isHistorical=${isHistorical}`);
    let data = response.data && response.data.data;

    dispatch({
      type: actionTypes.GET_TOTALS_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_TOTALS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getAssistanceAnalysisIndividualAction = (from, to, isHistorical, employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_INDIVIDUAL });
  try {
    let response = await getAssistanceAnalysisIndividualAPI(`?from=${from}&to=${to}&isHistorical=${isHistorical}&employeeCode=${employeeCode}`);
    let data = response.data && response.data.data;
    let absenceTypeList = response.data && response.data.absenceTypeList;

    dispatch({
      type: actionTypes.GET_INDIVIDUAL_FULFILLED,
      payload: {
        data,
        absenceTypeList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_INDIVIDUAL_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const executeTableFormAction = (isTotals, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EXECUTE });
  try {
    const apiToUse = isTotals ? executeAssistanceAnalysisTotalsAPI : executeAssistanceAnalysisIndividualAPI;

    let response = await apiToUse(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EXECUTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.EXECUTE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "El proceso se ha iniciado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EXECUTE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateObservedAssistanceAnalysisIndividualAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await updateObservedAssistanceAnalysisIndividualAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "Observaciones actualizadas con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getChangeScheduleAssistanceAnalysisIndividualAction = (code, date) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CHANGE_SCHEDULE });
  try {
    let response = await getChangeScheduleAssistanceAnalysisIndividualAPI(`?code=${code}&date=${date}`);
    let status = response.data && response.data.status;
    let schedules = response.data && response.data.schedules;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_CHANGE_SCHEDULE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_CHANGE_SCHEDULE_FULFILLED,
      payload: { schedules }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_CHANGE_SCHEDULE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const updateChangeScheduleAssistanceAnalysisIndividualAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await updateChangeScheduleAssistanceAnalysisIndividualAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "Cambio de horario realizado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const updateExtraHoursAssistanceAnalysisIndividualAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await updateExtraHoursAssistanceAnalysisIndividualAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "Horas extras autorizadas con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const updateJustifyAbsenceAssistanceAnalysisIndividualAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await updateJustifyAbsenceAssistanceAnalysisIndividualAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "Justificación realizada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};