
import React, { memo } from 'react';
import { Card, Grid, Typography } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

const getWordPercentage = (index, countArray) => {
    const total = countArray.reduce((prev, current) => prev + parseFloat(current), 0)
    return (parseFloat(countArray[index]) * 100 / total).toFixed(2);
}

function GHDGraph({ data, gridTheme, setGridDataAndOpenDialog, chartColors, path }) {
    return (
        <Card
            className="w-full rounded-20 shadow"
            style={{ minHeight: 200, cursor: "pointer", height: "100%" }}
            onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}
        >
            <div className="h-96 w-100-p" style={{ height: "100%" }}>
                <Grid container style={{ height: "100%" }}>
                    <Grid container direction="row" item sm={12} xs={12} alignItems="center" >
                        <Grid container item xs={11} style={{ minHeight: "70%", maxHeight: "90%" }} justify="center" alignItems="center">
                            <Grid container direction="column" item xs={12}>
                                <Typography className='whiteText' style={{ padding: "10px 20px 2px 20px", fontSize: 24, fontWeight: 700, textShadow: gridTheme === "dark" ? '1px 1px 2px black' : '' }}>
                                    {data.title}
                                </Typography>
                                <Typography className='whiteText' style={{ padding: "0px 20px", fontSize: 16, fontWeight: 300, textShadow: gridTheme === "dark" ? '1px 1px 2px black' : '' }}>
                                    {data.subtitle}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" item sm={12} xs={12} style={{ overflowY: "scroll", height: 100 }} alignItems="center" >
                        <Grid item xs={11} style={{ marginTop: 10 }}>
                            {
                                Object.keys(data.data).map((item, index) => (
                                    <Grid container direction='row' alignItems="center" item key={index} style={{ minHeight: 45, padding: "10px 20px" }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={10}>
                                                <Typography className='whiteText' style={{ fontSize: 24, fontWeight: 500 }}>
                                                    {item}
                                                </Typography>
                                            </Grid>
                                            <Grid container alignItems="center" item xs={2} style={{ paddingLeft: 10 }}>
                                                <Grid item xs={10} >
                                                    <div
                                                        style={{
                                                            height: 15,
                                                            width: `${getWordPercentage(index, Object.values(data.data))}%`,
                                                            backgroundColor: chartColors[index]
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} style={{ paddingLeft: 10 }}>
                                                    <Typography className='whiteText' style={{ fontSize: 16 }}>
                                                        {formatNumberOrReturnUndefined(parseFloat(data.data[item]), 0, 2)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Card >
    )
}

function preventRender() {
    return true;
}

export default memo(GHDGraph, preventRender);