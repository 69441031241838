import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
class NoTotemDialog extends React.PureComponent {
  render() {
    const {
      open,
      onClick,
    } = this.props;

    return <Dialog
      open={open}
      PaperComponent={PaperDraggable}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("atention")}
          <DialogTitleDivider />
        </DialogTitle>
        <DialogContent>
          <Typography className="whiteText" varirant="subtitle1">{getLocalizedString("noTotemFound")}</Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction
            onClick={onClick}
            isAccept
            text={getLocalizedString("agree")}
          />  
        </DialogActions>
      </div>
    </Dialog>
  }
}

export default NoTotemDialog;
