import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  totalData: [],
  individualData: [],
  absenceTypeList: [],
  openCalendarList: [],
  historicalCalendarList: [],
  specialProcessParameters: null,
  schedules: [],
  breakIsEditable: false,
  isLoadingDashboard: false,//Va uno extra porque tarda una barbaridad
  dashboardData: null,
  dashboardDesc: null,
  currency_localization: "da"
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        openCalendarList: action.payload.openCalendarList,
        historicalCalendarList: action.payload.historicalCalendarList,
        specialProcessParameters: action.payload.specialProcessParameters,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_TOTALS:
      return { ...state, isLoading: true };
    case actionTypes.GET_TOTALS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        totalData: action.payload.data,
      };
    case actionTypes.GET_TOTALS_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_INDIVIDUAL:
      return { ...state, isLoading: true };
    case actionTypes.GET_INDIVIDUAL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        individualData: action.payload.data,
        absenceTypeList: action.payload.absenceTypeList,
      };
    case actionTypes.GET_INDIVIDUAL_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.EXECUTE:
      return { ...state, isLoading: true };
    case actionTypes.EXECUTE_FULFILLED:
    case actionTypes.EXECUTE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_CHANGE_SCHEDULE:
      return { ...state, isLoading: true };
    case actionTypes.GET_CHANGE_SCHEDULE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        schedules: action.payload.schedules,
      };
    case actionTypes.GET_CHANGE_SCHEDULE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        breakIsEditable: false,
      };
    case actionTypes.UPDATE_REJECTED:
      return { ...state, isLoading: false };


    case actionTypes.GET_DASHBOARD:
      return { ...state, isLoadingDashboard: action.payload.option === "quantity" };
    case actionTypes.GET_DASHBOARD_FULFILLED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardData: {
          ...state.dashboardData,
          [`${action.payload.option}`]: action.payload.data.data
        },
        currencyLocalization: action.payload.currency_localization,
        dashboardDesc: action.payload.desc,
      };
    case actionTypes.GET_DASHBOARD_REJECTED:
      return { ...state, isLoadingDashboard: false };
    case actionTypes.RESET_STATE_DASHBOARD:
      return { ...state, dashboardData: null, dashboardDesc: null }

    default:
      return state;
  }
}
