import NAME from "./constants";

export const requestingVersion = store => store[NAME].requestingVersion;
export const getVersion = store => store[NAME].version;
export const getUnixTimestamp = store => store[NAME].unixTimestamp;
export const requestingLogin = store => store[NAME].requestingLogin || store[NAME].changePin || store[NAME].resetPasswordIsLoading;
export const getLoginResponse = store => store[NAME].loginResponse;
export const getSocieties = store => {
  try {
    return (
      (store[NAME].loginResponse &&
        store[NAME].loginResponse.user &&
        store[NAME].loginResponse.user.societies) ||
      []
    );
  } catch (e) {
    return [];
  }
};
export const isAuthorized = store => store[NAME].loginResponse && store[NAME].societySelected;
export const getSocietySelected = store => store[NAME].societySelected;
export const getTime = store => store[NAME].time;