import React from "react";
import { Grid, Typography } from "@material-ui/core";

const SingleItemTable = (props) => {

    const { data, title, color } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" style={{ color: color }}>{"Por fecha"}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "0px 6px 6px 0px" }}>
            </Grid>
            {
                Object.keys(data).map((key, index) => (
                    <Grid key={index} item container xs={12}>
                        <Typography style={{ marginRight: 5, fontWeight: "bold" }}>{key + ":"}</Typography>
                        <Typography>{data[key]}</Typography>
                    </Grid>
                )
                )}
        </Grid>
    );
}

export default SingleItemTable;
