import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { useDispatch } from "react-redux";
import {
  createDateFromHHMM,
  formatDateHHMM,
  createDateFromDDMMYYYY,
} from "@icarius-utils/date";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { completeAssistanceMarkAction } from "../../actions";
import moment from "moment";

const gridStyle = { padding: "10px" };

const ExtraHoursAuthDialog = (props) => {

  const {
    open,
    isLoading,
    handleClose,
    selectedMark,
  } = props;

  const hasEntry = selectedMark["FECHA DE ENTRADA"] !== null;

  const [hours, setHours] = useState(createDateFromHHMM("00:00"));

  const [date, setDate] = useState(() => {
    // Seteo como día minimo el que corresponde
    if (hasEntry) {
      return moment(createDateFromDDMMYYYY(selectedMark["FECHA DE ENTRADA"])).toDate();
    } else {
      return moment(createDateFromDDMMYYYY(selectedMark["FECHA DE SALIDA"])).subtract(1, "days").toDate();
    }
  });

  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    let entryMark = selectedMark["FECHA DE ENTRADA"];
    let exitMark = selectedMark["FECHA DE SALIDA"];

    let formattedDate = [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('-');

    let auxDate = createDateFromDDMMYYYY(hasEntry ? selectedMark["FECHA DE ENTRADA"] : selectedMark["FECHA DE SALIDA"]);
    auxDate = [
      auxDate.getFullYear(),
      ('0' + (auxDate.getMonth() + 1)).slice(-2),
      ('0' + auxDate.getDate()).slice(-2)
    ].join('-');

    if (entryMark) {
      entryMark = new Date(`${auxDate} ${selectedMark["HORA DE ENTRADA"]}:00`);
      exitMark = new Date(`${formattedDate} ${String(hours.getHours()).padStart(2, "0")}:${String(hours.getMinutes()).padStart(2, "0")}:00`);
    } else {
      entryMark = new Date(`${formattedDate} ${String(hours.getHours()).padStart(2, "0")}:${String(hours.getMinutes()).padStart(2, "0")}:00`);
      exitMark = new Date(`${auxDate} ${selectedMark["HORA DE SALIDA"]}:00`);
    }

    // Verifico que las dos fechas sean días contiguos
    if (moment(exitMark).diff(entryMark, "days") > 1) {
      dispatch(openSnackbarAction({ msg: 'El día de entrada y el de salida deben ser contiguos', severity: "error", duration: 10000 }));
      return;
    }

    // Verifico que la fecha de salida sea mayor o igual que la de entrada
    if (moment(exitMark).diff(entryMark, "minutes") < 0) {
      dispatch(openSnackbarAction({ msg: 'La fecha y hora de salida debe ser mayor o igual que la fecha y hora de entrada', severity: "error", duration: 10000 }));
      return;
    }

    const dataToSend = {
      type: hasEntry ? "exit" : "entry",
      internalCode: selectedMark["internalCode"],
      date: moment(date).format("DD/MM/YYYY"),
      time: formatDateHHMM(hours),
      comment
    }

    dispatch(completeAssistanceMarkAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
        }
      });
  }

  if (isLoading) return null;

  const handleChangeHours = (value) => {
    setHours(value);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      fullWidth
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`${selectedMark["APELLIDO Y NOMBRE"]} - Completar la marca de ${hasEntry ? "salida" : "entrada"} para la marca de ${hasEntry ? "entrada" : "salida"} del ${hasEntry ? selectedMark["FECHA DE ENTRADA"] : selectedMark["FECHA DE SALIDA"]} a las ${hasEntry ? selectedMark["HORA DE ENTRADA"] : selectedMark["HORA DE SALIDA"]}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                required
                fullWidth
                label={`Fecha de ${hasEntry ? "salida" : "entrada"}`}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                format="dd/MM/yyyy"
                margin="normal"
                minDate={hasEntry ? createDateFromDDMMYYYY(selectedMark["FECHA DE ENTRADA"]) : moment(createDateFromDDMMYYYY(selectedMark["FECHA DE SALIDA"])).subtract(1, "days")}
                maxDate={hasEntry ? moment(createDateFromDDMMYYYY(selectedMark["FECHA DE ENTRADA"])).add(1, "days") : createDateFromDDMMYYYY(selectedMark["FECHA DE SALIDA"])}
                value={date}
                onChange={(e) => setDate(e)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item xs={12} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardTimePicker
                required
                label={`Horas de ${hasEntry ? "salida" : "entrada"}`}
                value={hours}
                onChange={(e) => handleChangeHours(e)}
                invalidDateMessage=""
                minDateMessage=""
                minutesStep={5}
                fullWidth
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                format="HH:mm"
                margin="none"
                ampm={false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
            <TextField
              fullWidth
              multiline
              rows={5}
              margin={"none"}
              label={'Comentarios'}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              inputProps={{ maxLength: 100, spellCheck: 'false' }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ExtraHoursAuthDialog;
