import React from "react";
import { compose, withProps, withHandlers, withStateHandlers } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps";
import Spiderfy from "./spiderfy";
import SingleItemTable from "./singleItemTable";
import { BASE_URL } from "@icarius-connection/endpoints";
import { GOOGLE_MAPS_KEY } from "@icarius-utils/properties";

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&v=3.exp&language=es&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ marginTop: 20, height: "calc(100vh - 225px)" }} />,
        containerElement: <div style={{ marginTop: 20, height: "calc(100vh - 225px)" }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withStateHandlers(
        ({ initialArray = [] }) => ({
            openArray: initialArray,
        }),
        {
            setDefault: () => (length) => ({
                openArray: [...Array(length).fill(false)],
            }),
            setOpen: ({ openArray }) => (pos) => ({
                openArray: openArray.map(function (item, i) { return pos === i ? !item : item; })
            }),
        }),
    withHandlers(() => {
        let refs = {
            map: undefined,
        }

        return {
            onMapMounted: () => (newArray, ref) => {
                if (refs.map) return;
                refs.map = ref
                const data = newArray
                let bounds = new window.google.maps.LatLngBounds();
                if (data.length > 0) {
                    if (data[0]["latitude"] && data[0]["longitude"]) {
                        for (let i = 0; i < data.length; i++) {
                            const { latitude, longitude } = data[i];
                            const position = {
                                lat: parseFloat(latitude), lng: parseFloat(longitude)
                            }
                            bounds.extend(position);
                        }
                    }

                    // Don't zoom in too far on only one marker
                    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                        let extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.001, bounds.getNorthEast().lng() + 0.001);
                        let extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.001, bounds.getNorthEast().lng() - 0.001);
                        bounds.extend(extendPoint1);
                        bounds.extend(extendPoint2);
                    }

                    refs.map.fitBounds(bounds);
                }
            },
        }
    }),
    withScriptjs,
    withGoogleMap
)((props) => {

    const { data, openArray, setDefault, setOpen, color, onMapMounted, onBoundsChanged } = props;
    if (data.length > 0 && openArray.length === 0) {
        setDefault(data.length)
    }

    let newArray = data.length > 0 ? data.filter(marker => marker.latitude && marker.longitude) : [];
    const array = newArray.map((marker, index) => {
        const { title, latitude, longitude, popup_grid } = marker;

        const iconToUse = {
            url: `${BASE_URL}/images/KPI/light/alarm_clock.svg`,
            scaledSize: new window.google.maps.Size(30, 30),
            labelOrigin: new window.google.maps.Point(16, 39),
            size: new window.google.maps.Size(30, 30),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(11, 40),
        };


        const label = {
            color: 'black',
            fontWeight: 'bold',
            text: title,
        }

        return (
            <Marker
                key={title}
                title={`${title.split(" - ")[2]} - ${title.split(" - ")[3]}`}
                icon={iconToUse}
                label={label}
                position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
            >
                {
                    openArray[index] &&
                    <InfoWindow onCloseClick={() => setOpen(index)}>
                        <SingleItemTable
                            data={popup_grid}
                            title={title}
                            color={color}
                        />
                    </InfoWindow>
                }
            </Marker>
        )
    });

    return (
        <GoogleMap
            defaultCenter={{ lat: 0, lng: 0 }}
            ref={(ref) => onMapMounted(newArray, ref)}
            defaultZoom={8}
            onBoundsChanged={onBoundsChanged}>
            <Spiderfy onSpiderClick={(index) => setOpen(index)}>
                {array}
            </Spiderfy>
        </GoogleMap>
    )
}
);

export default MyMapComponent;