import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FULFILLED,
  REQUEST_VERSION,
  REQUEST_VERSION_FULFILLED,
  REQUEST_LOGIN_REJECTED,
  CHANGE_PIN_REJECTED,
  CHANGE_PIN,
  CHANGE_PIN_FULFILLED,
  RESET_PASSWORD,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_FULFILLED_REJECTED,
  SET_TIME
} from "./actionTypes";
import { openDialogAction, openErrorDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { getVersion } from "./selectors";
import {
  getVersionAPI,
  registerAssistanceTotemAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const RE_FETCH_TIMEOUT = 5000;

export const getVersionAction = async (dispatch, getState) => {
  if (getVersion(getState())) return;

  dispatch({ type: REQUEST_VERSION });
  try {
    let response = await getVersionAPI();

    dispatch({
      type: REQUEST_VERSION_FULFILLED,
      payload: {
        version: response.data && response.data.version,
        unixTimestamp: response.data && response.data.unix_timestamp,
      }
    });
    return response;
  } catch (e) {
    setTimeout(() => dispatch(getVersionAction), RE_FETCH_TIMEOUT);
  }
};

export const registerAssistanceTotemAction = (totem, type, client, user, pin) => async (dispatch) => {
  dispatch({ type: REQUEST_LOGIN });

  try {
    const upperCaseClient = client.toUpperCase();
    let response = await registerAssistanceTotemAPI({
      totem: totem,
      type: type,
      client: upperCaseClient,
      user: user,
      password: pin,
    });

    let noTotem = false;

    if (response.status === 200) {

      let text = "";

      switch (response.data.status) {
        case "OK":
          dispatch(openSnackbarAction({ msg: getLocalizedString("registryOK").replace("{Move}", type === "exit" ? getLocalizedString("salida") : getLocalizedString("entrada")), severity: "success" }));
          break;
        case "INVALID_CREDENTIALS":
          text = getLocalizedString("invalidAssistanceCredentials");
          break;
        case "CLIENT_DISABLED_APP":
          text = getLocalizedString("assistanceRegistryDisabled");
          break;
        case "CLIENT_EXPIRED_LICENSE":
          text = getLocalizedString("assistanceLicenceExpired");
          break;
        case "CLIENT_ACCESS_DENIED":
          text = getLocalizedString("assistanceRegistryDisabled");
          break;
        case "NOT_COLABORATOR":
          text = getLocalizedString("cantRegisterAssistance");
          break;
        case "ERROR_ASISTENCE":
          text = getLocalizedString("cantRegisterAssistanceVacation");
          break;
        case "BLOCKED":
          text = getLocalizedString("registerBlocked");
          break;
        case "MARKED_INASISTENCE":
          text = getLocalizedString("registerMarkedInasistance");
          break;
        case "TOTEM_NOT_FOUND":
        default:
          noTotem = true;
          break;
      }
      text !== "" && dispatch(openSnackbarAction({ msg: text, severity: "error" }));
    }

    dispatch({ type: REQUEST_LOGIN_FULFILLED, payload: response.data });
    return noTotem ? "TOTEM_NOT_FOUND" : response;
  } catch (e) {
    dispatch({ type: REQUEST_LOGIN_REJECTED, payload: e });
    if (e.response.data.status === "VALIDATION_ERROR") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("validationErrorRequests"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
    }
  }
};

export const forgotPasswordAction = (user, client) => async dispatch => {
  dispatch({ type: CHANGE_PIN });
  try {
    let response = await forgotPasswordAPI({ client: client.toUpperCase(), user });
    dispatch({ type: CHANGE_PIN_FULFILLED, payload: response.data });
    dispatch(openDialogAction({ msg: getLocalizedString("mailSent"), acceptOnly: true }));
    return response;
  } catch (e) {
    dispatch({ type: CHANGE_PIN_REJECTED, payload: e });
    dispatch(openErrorDialogAction(e));
  }
};

export const resetPasswordAction = (code) => async dispatch => {
  dispatch({ type: RESET_PASSWORD });
  try {
    let response = await resetPasswordAPI({ code });
    dispatch({ type: RESET_PASSWORD_FULFILLED });

    if (response.data.status === "OK") {
      dispatch(
        openDialogAction({
          msg: getLocalizedString("pinRegenerationConfirmed"),
          acceptOnly: true,
        })
      );
    }

    return response;
  } catch (e) {
    dispatch({ type: RESET_PASSWORD_FULFILLED_REJECTED });
  }
};

export const setTime = time => async (dispatch) => {
  dispatch({ type: SET_TIME, payload: time });
};