import React from "react";
import TotemLogin from "./totemLogin";
import withAnimatedMount from "@icarius-common/animatedMount";
import Loader from "@icarius-common/loader";
import { requestingLogin } from "../selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { resetPasswordAction } from "../actions";
import { Grid } from "@material-ui/core";
import { isKeyPresent, getValueFromKey } from "@icarius-utils/localStorage";
import { Login as IcariusLogin } from "@icarius-pages";

const Login = (props) => {

  const { loading, dispatch } = props;

  //RegenPinCode
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const clientCode = params.get("client");
  const regenPinCode = params.get("regenPinCode");

  if (regenPinCode) {
    dispatch(resetPasswordAction(regenPinCode));
  }

  const totem = isKeyPresent("assistance-totem-id") && getValueFromKey("assistance-totem-id");
  const workplace = isKeyPresent("assistance-totem-id") && getValueFromKey("assistance-totem-workplace");
  const society = isKeyPresent("assistance-totem-id") && getValueFromKey("assistance-totem-society-name");
  const timezone = isKeyPresent("assistance-totem-id") && getValueFromKey("assistance-totem-timezone");

  if (!(totem && workplace && society && timezone)) return <IcariusLogin />;

  return (
    <section id={"login-page"} style={{ backgroundColor: "#121212" }}>
      <Loader open={loading} />
      <Grid container justify="center" >
        <TotemLogin
          clientCode={clientCode}
          workplace={workplace}
          totem={totem}
          society={society}
          timezone={timezone}
        />
      </Grid>
    </section>
  );
}

export default withAnimatedMount(
  connect(
    createStructuredSelector({
      loading: requestingLogin,
    })
  )(Login)
);
