import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { useDispatch } from "react-redux";
import { updateObservedAssistanceAnalysisIndividualAction } from "../../actions";

const UpdateObservedDialog = (props) => {

  const {
    open,
    code,
    dates,
    isMark,
    isLoading,
    handleClose,
    handleFetchAfterSubmit,
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const dataToSend = {
      code,
      dates,
      value: isMark ? 'Y' : 'R',
    }

    dispatch(updateObservedAssistanceAnalysisIndividualAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleFetchAfterSubmit();
          handleClose();
        }
      });
  }

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Actualizar observaciónes"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Typography className="whiteText">
              {
                isMark ?
                  '¿Desea marcar los registros seleccionados como observados?'
                  : '¿Desea quitar la observación de los registros seleccionados?'
              }
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default UpdateObservedDialog;
