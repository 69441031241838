import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { useDispatch } from "react-redux";
import {
  createDateFromHHMM,
  getDateIsValid,
  formatDateHHMM,
  getHoursMinutesBetweenDates,
  createDateFromYYYYMMDD,
  formatDate,
} from "@icarius-utils/date";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { updateExtraHoursAssistanceAnalysisIndividualAction } from "../../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";

const gridStyle = { padding: "10px" };

const ExtraHoursAuthDialog = (props) => {

  const {
    open,
    day,
    code,
    date,
    extraHoursTotal,
    noAuthExtraHours,
    isLoading,
    handleClose,
    handleFetchAfterSubmit,
  } = props;

  const [currentNoAuthExtraHours, setCurrentNoAuthExtraHours] = useState(createDateFromHHMM(noAuthExtraHours));
  const [authHours, setAuthHours] = useState(() => {
    // setear como valor inicial la resta entre las horas extras total - las horas extras no autorizadas (eso da el valor de horas extras autorizadas)
    const extraHoursTotalDateObject = createDateFromHHMM(extraHoursTotal);
    const noAuthExtraHoursDateObject = createDateFromHHMM(noAuthExtraHours);
    return getHoursMinutesBetweenDates(extraHoursTotalDateObject, noAuthExtraHoursDateObject);
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!getDateIsValid(authHours)) {
      dispatch(openSnackbarAction({ msg: 'El valor ingresado no es válido', severity: "error" }));
      return;
    }

    const dataToSend = {
      code,
      date,
      authHours: formatDateHHMM(authHours),
    }

    if (dataToSend.authHours === "00:00") {
      dispatch(openDialogAction({
        title: getLocalizedString("atention"),
        msg: '¿Está seguro que no autoriza ninguna hora extra (00:00)?',
        onConfirm: () => dispatch(updateExtraHoursAssistanceAnalysisIndividualAction(dataToSend))
          .then((res) => {
            if (res?.status === 200) {
              handleFetchAfterSubmit();
              handleClose();
            }
          }),
      }));
    } else {
      dispatch(updateExtraHoursAssistanceAnalysisIndividualAction(dataToSend))
        .then((res) => {
          if (res?.status === 200) {
            handleFetchAfterSubmit();
            handleClose();
          }
        });
    }
  }
  if (isLoading) return null;

  const handleChangeHours = (value) => {
    if (value > createDateFromHHMM(extraHoursTotal)) {
      dispatch(openSnackbarAction({ msg: 'El valor ingresado no puede ser mayor al total de horas extra', severity: "error", duration: 10000 }));
      value.setHours(0);
      value.setMinutes(0);
      value.setSeconds(0);
      setAuthHours(value);
      return;
    }

    setAuthHours(value);
    if (getDateIsValid(value)) {
      setCurrentNoAuthExtraHours(() => {
        // las horas no autorizadas salen de la resta entre el total de horas extra - horas extras autorizadas 
        return getHoursMinutesBetweenDates(createDateFromHHMM(extraHoursTotal), value)
      })
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      fullWidth
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {`Autorizar horas extra: ${day} ${formatDate(createDateFromYYYYMMDD(date))}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container item direction="column" justify="center" xs={12} style={gridStyle}>
            <Typography className="whiteText" style={{ fontSize: 12 }}>{'Total horas extra realizadas'}</Typography>
            <Typography className="whiteText">{extraHoursTotal}</Typography>
          </Grid>
          <Grid container item xs={12} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardTimePicker
                required
                label={'Horas extras autorizadas'}
                value={authHours}
                onChange={(e) => handleChangeHours(e)}
                invalidDateMessage=""
                minDateMessage=""
                minutesStep={5}
                fullWidth
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                format="HH:mm"
                margin="none"
                ampm={false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item direction="column" xs={12} style={gridStyle}>
            <Typography className="whiteText" style={{ fontSize: 12 }}>{'Horas extra sin autorizar'}</Typography>
            <Typography className="whiteText">{formatDateHHMM(currentNoAuthExtraHours)}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ExtraHoursAuthDialog;
