import NAME from "./constants";

export const GET_ASSISTANCE_ROWS = NAME + "/GET_ASSISTANCE_ROWS";
export const GET_ASSISTANCE_ROWS_FULFILLED = NAME + "/GET_ASSISTANCE_ROWS_FULFILLED";
export const GET_ASSISTANCE_ROWS_REJECTED = NAME + "/GET_ASSISTANCE_ROWS_REJECTED";

export const GET_MAP_DATA = NAME + "/GET_MAP_DATA";
export const GET_MAP_DATA_FULFILLED = NAME + "/GET_MAP_DATA_FULFILLED";
export const GET_MAP_DATA_REJECTED = NAME + "/GET_MAP_DATA_REJECTED";

export const COMPLETE_ASSISTANCE_MARK = NAME + "/COMPLETE_ASSISTANCE_MARK";
export const COMPLETE_ASSISTANCE_MARK_FULFILLED = NAME + "/COMPLETE_ASSISTANCE_MARK_FULFILLED";
export const COMPLETE_ASSISTANCE_MARK_REJECTED = NAME + "/COMPLETE_ASSISTANCE_MARK_REJECTED";
