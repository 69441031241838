/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Typography, Button, CircularProgress, Grid } from "@material-ui/core";
import { getLocalizedString, setLanguage, getLocalizedErrorString } from "@icarius-localization/strings";
import {
  getVersionAction,
  registerAssistanceTotemAction,
  forgotPasswordAction,
} from "../actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getLoginResponse,
  getSocieties,
  getVersion,
  requestingLogin,
  requestingVersion,
} from "../selectors";
import CustomTextField from "@icarius-common/input/textField";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
import { openDialogAction, openErrorDialogAction } from "@icarius-common/dialog/actions";
import {
  MAX_CLIENT_LENGTH,
  MAX_PIN_LENGTH,
  MAX_USER_LENGTH,
  MIN_CLIENT_LENGTH,
  MIN_PIN_LENGTH,
  MIN_USER_LENGTH,
} from "@icarius-utils/constants";
import IcariusLogo from "@icarius-assets/images/icariusLogo";
import { deleteKey, isKeyPresent, getValueFromKey } from "@icarius-utils/localStorage";
import { generalColors } from "@icarius-utils/colors";
import Clock from "./clock";
import { Snackbar } from "@icarius-common/snackbar";
import NoTotemDialog from "./noTotemDialog";

class TotemLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      client: isKeyPresent("icarius-client") ? getValueFromKey("icarius-client") : "",
      user: "",
      pin: "",
      noTotemDialogIsOpen: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.focusRef = React.createRef();

    if (isKeyPresent("language"))
      setLanguage(getValueFromKey("language"));
  }

  render() {
    const {
      version,
      requestingVersion,
      workplace,
      society,
      timezone,
    } = this.props;

    const { client, user, pin, noTotemDialogIsOpen } = this.state;

    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const porcentaje = 0.60;
    const padding = 21;
    const maxWidthContainer = 500;

    return (
      <section id={"left-panel"} style={{ backgroundColor: "#121212", width: windowWidth * porcentaje, maxWidth: maxWidthContainer }}>
        <form ref="loginForm" style={{ height: "100%", maxHeight: "820px" }} autoComplete="on" onSubmit={this.submitForm}>
          <Snackbar alignCenter />
          {
            noTotemDialogIsOpen &&
            <NoTotemDialog
              open={noTotemDialogIsOpen}
              onClick={this.handleCloseNoTotemDialog}
            />
          }
          <Grid container direction={"column"} justify="space-evenly" align="center" style={{ height: "100%" }}>
            <Grid item>
              <IcariusLogo theme={"dark"} height={50} />
            </Grid>
            <Grid item container direction="column">
              <Typography variant="subtitle1" style={{ color: 'white', fontWeight: 500, lineHeight: "1.25rem", fontSize: "1.25rem" }}>
                {society}
              </Typography>
              <Typography variant="subtitle1" style={{ color: 'white', fontWeight: 100, textTransform: "uppercase", fontSize: "1.25rem" }}>
                {workplace}
              </Typography>
            </Grid>
            <Grid item style={{ borderTop: `2px solid ${generalColors.red}`, borderBottom: `2px solid ${generalColors.red}` }}>
              <Clock timezone={timezone} />
            </Grid>
            <Grid item container direction="column" alignItems="center">
              <div style={{ paddingTop: padding / 2, width: "100%" }}>
                <CustomTextField
                  noAdornment
                  isValid={
                    !!client && client.length >= MIN_CLIENT_LENGTH && client.length <= MAX_CLIENT_LENGTH
                  }
                  label={getLocalizedString("client")}
                  value={client}
                  type={"text"}
                  maxWidth
                  isClient
                  showState
                  autoComplete
                  onChange={value => this.handleTextToUpperChange("client", value)}
                  login={true}
                />
              </div>
              <div className={"center-input"} style={{ paddingTop: padding / 2, width: "100%" }}>
                <CustomTextField
                  ref={this.focusRef}
                  autoFocus
                  noAdornment
                  isValid={!!user && user.length >= MIN_USER_LENGTH && user.length <= MAX_USER_LENGTH}
                  label={getLocalizedString("user")}
                  value={user}
                  maxWidth
                  autoComplete
                  type={"text"}
                  showState
                  onChange={value => this.handleTextToUpperChange("user", value)}
                  login={true}
                />
              </div>
              <div style={{ paddingTop: padding / 2, width: "100%" }}>
                <CustomTextField
                  noAdornment
                  isValid={
                    valueIsOfType(pin, valueTypes.pin) &&
                    pin.length >= MIN_PIN_LENGTH &&
                    pin.length <= MAX_PIN_LENGTH
                  }
                  label={getLocalizedString("pin") + " (" + getLocalizedString("key") + ")"}
                  value={pin}
                  maxWidth
                  showState
                  autoComplete
                  type={"password"}
                  onChange={value => this.handleTextChange("pin", value)}
                  login={true}
                />
              </div>
            </Grid>
            <Grid item container justify="space-around" align="center">
              <Button
                disabled={!this.isDataValid()}
                style={{
                  width: windowWidth * (porcentaje / 2) - (padding / 2),
                  maxWidth: (maxWidthContainer / 2) - (padding / 2),
                  borderRadius: "6px",
                  backgroundColor: generalColors.red,
                  height: "52px",
                }}
                disableRipple={true}
                variant={"contained"}
                className={"login-button"}
                type={"submit"}
                onClick={() => this.handleRegister("entry")}>
                <Typography style={{ textTransform: "none", fontSize: "22px", color: "white" }} variant={"button"}>
                  {getLocalizedString("entrada")}
                </Typography>
              </Button>
              <Button
                disabled={!this.isDataValid()}
                style={{
                  width: windowWidth * (porcentaje / 2) - (padding / 2),
                  maxWidth: (maxWidthContainer / 2) - (padding / 2),
                  borderRadius: "6px",
                  backgroundColor: generalColors.red,
                  height: "52px",
                }}
                disableRipple={true}
                variant={"contained"}
                type={"submit"}
                className={"login-button"}
                onClick={() => this.handleRegister("exit")}>
                <Typography style={{ textTransform: "none", fontSize: "22px", color: "white" }} variant={"button"}>
                  {getLocalizedString("salida")}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Typography
                style={{ color: 'white' }}
                variant={"subtitle1"}
                className={"recover-pin"}
                onClick={this.handleRecoverPinClick}>
                {getLocalizedString("recoverPinAccessCode")}
              </Typography>
              <Grid item container direction="column">
                <Typography className={"version-text"}>{getLocalizedString("copyright").replace("{year}", new Date().getFullYear())}</Typography>
              </Grid>
              <Grid container orientation="row" justify={"center"} alignContent={"center"}>
                <Grid item style={{ marginRight: 5 }}>
                  <Typography className={"version-text"}>
                    {getLocalizedString("version")}: {requestingVersion || !version ? "" : version.replace(",", ".")}
                  </Typography>
                </Grid>
                {
                  requestingVersion &&
                  <Grid item>
                    <CircularProgress size={20} className={"version-progress"} />
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </form>
      </section>
    );
  }

  submitForm = e => {
    //De esta forma, se permite que el navegador guarde las credenciales
    // y que React siga funcionando de forma correcta
    e.preventDefault();
  };

  componentDidMount() {
    const { dispatch, version, clientCode } = this.props;
    if (!version) dispatch(getVersionAction);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    if (clientCode) {
      this.setState({ client: clientCode });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleTextChange = (name, value) => this.setState({ [name]: value });
  handleTextToUpperChange = (name, value) => this.setState({ [name]: value.toUpperCase() });

  handleCloseNoTotemDialog = () => {
    this.setState({ noTotemDialogIsOpen: false });
    //redirigir al login
    window.location.href = '';
  }

  isDataValid = () => {
    const { client, user, pin } = this.state;

    return client !== "" && user !== "" && pin !== "" &&
      client && client.length >= MIN_CLIENT_LENGTH && client.length <= MAX_CLIENT_LENGTH &&
      user && user.length >= MIN_USER_LENGTH && user.length <= MAX_USER_LENGTH &&
      valueIsOfType(pin, valueTypes.pin) && pin.length >= MIN_PIN_LENGTH && pin.length <= MAX_PIN_LENGTH
  }

  handleRegister = type => {
    const { dispatch, totem } = this.props;
    const { client, user, pin } = this.state;

    if (this.isDataValid()) {
      this.focusRef.current.focus();

      dispatch(registerAssistanceTotemAction(totem, type, client, user, pin)).then((resp) => {
        if (resp === "TOTEM_NOT_FOUND") {
          //mostrar dialog que al cerrar redireccione al login
          this.setState({ noTotemDialogIsOpen: true })

          deleteKey("assistance-totem-id");
          deleteKey("assistance-totem-workplace");
          deleteKey("assistance-totem-society-name");
          deleteKey("assistance-totem-timezone");
        }
        this.setState({ user: "", pin: "" })
      });
    }
  };

  handleRecoverPinClick = () => {
    const { client, user } = this.state;
    const { dispatch } = this.props;

    if (!client || !user) {
      dispatch(openErrorDialogAction({ msg: getLocalizedErrorString("invalidInfo") }));
      return;
    }

    this.props.dispatch(
      openDialogAction({
        msg: getLocalizedString("recoverPinDialogText").replace("{user}", user).replace("{client}", client),
        onConfirm: () => dispatch(forgotPasswordAction(user, client)),
        title: getLocalizedString("pinWillChange"),
      })
    );
  };
}

export default connect(
  createStructuredSelector({
    requestingVersion: requestingVersion,
    version: getVersion,
    requestingLogin: requestingLogin,
    loginResponse: getLoginResponse,
    societies: getSocieties,
  })
)(TotemLogin);
