import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid, getAppColor } from "src/app/selectors";
import { RoomIcon, EventAvailableIcon } from "@icarius-icons";
import paths from "@icarius-localization/paths";
import { Typography } from "@material-ui/core";
import PositionImageRenderer from "@icarius-table/renderersAndFilters/positionImageRenderer";
import TimeBetweenMarksRenderer from "@icarius-table/renderersAndFilters/timeBetweenMarksRenderer";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import { openDialogAction } from "@icarius-common/dialog/actions";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import { getAssistanceTableRows, getMarksForMap } from "../actions";
import { getAssistanceRows, getDateFormat, getLocale, getIsLoading, getMapData } from "../selectors";
import MyMapComponent from "../../assistance/components/map/myMapComponent";
import CompleteAssistanceMarkDialog from "./dialogs/completeAssistanceMarkDialog";

const Assistance = () => {

  const [mapIsOpen, setMapIsOpen] = useState(false);
  const [completeAssistanceMarkDialogIsOpen, setCompleteAssistanceMarkDialogIsOpen] = useState(false);
  const [selectedMark, setSelectedMark] = useState(null);
  const [showAllRows, setshowAllRows] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssistanceTableRows());
  }, [dispatch])

  const isLoading = useSelector(getIsLoading);
  const isExportingGrid = useSelector(getIsExportingGrid);
  const assistanceData = useSelector(getAssistanceRows);
  const mapData = useSelector(getMapData);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const color = useSelector(getAppColor);

  const rowData = showAllRows ?
    [...assistanceData] :
    [...assistanceData].filter(row => !(row["FECHA DE ENTRADA"] !== null && row["FECHA DE SALIDA"] !== null));

  const handleOpenCompleteAssistanceMark = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (
      selectedRows[0]["FECHA DE ENTRADA"] !== null &&
      selectedRows[0]["FECHA DE SALIDA"] !== null
    ) {
      dispatch(openSnackbarAction({ msg: 'La marca de asistencia seleccionada ya está completa', severity: "warning" }));
      return;
    }

    setSelectedMark(selectedRows[0]);
    setCompleteAssistanceMarkDialogIsOpen(true);
  }

  const handleCloseCompleteAssistanceMark = () => {
    setCompleteAssistanceMarkDialogIsOpen(false);
    setSelectedMark(null);
  }

  const handleOpenMap = (gridRef) => {
    let mapDataToUse = [];

    if (mapIsOpen) {
      setMapIsOpen(false);
      return;
    }

    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    if (selectedRows.some((row) => !row.hasEntryGPSCoordinates && !row.hasExitGPSCoordinates)) {
      dispatch(openDialogAction({
        title: getLocalizedString("atention"),
        msg: "No existe geoposición en la marca de asistencia para mostrar en el mapa",
        acceptOnly: true
      }));
      return;
    }

    mapDataToUse = selectedRows
      .map((row) => ({
        employeeCode: row["CODIGO DE EMPLEADO"],
        entryDate: row["FECHA DE ENTRADA"],
        exitDate: row["FECHA DE SALIDA"],
      }))
      .filter((row) => row.employeeCode && (row.entryDate || row.exitDate));

    if (!mapDataToUse.length) {
      dispatch(openDialogAction({
        title: getLocalizedString("atention"),
        msg: "Las marcas de asistencia deben tener fecha de entrada o salida",
        acceptOnly: true
      }));
      return;
    }

    dispatch(getMarksForMap(mapDataToUse))
      .then((resp) => {
        if (resp?.status === 200) {
          setMapIsOpen(true);
        }
      });
  }
  const completAssistanceMarkItem = (gridRef) => assistanceData.length > 0 &&
    <CustomIconButton
      title={"Completar marca de asistencia"}
      onClick={() => handleOpenCompleteAssistanceMark(gridRef)}
    >
      <EventAvailableIcon />
    </CustomIconButton>

  const mapItem = (gridRef) => assistanceData.length > 0 &&
    <CustomIconButton
      title={getLocalizedString(mapIsOpen ? "closeMap" : "openMap")}
      onClick={() => handleOpenMap(gridRef)}
    >
      <RoomIcon />
    </CustomIconButton>

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => setMapIsOpen(false)}
      type={"goBack"}
    />

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
      {
        mapIsOpen &&
        <MyMapComponent
          data={mapData}
          color={color}
          isMarkerShown
        />
      }
      {
        completeAssistanceMarkDialogIsOpen &&
        <CompleteAssistanceMarkDialog
          open={completeAssistanceMarkDialogIsOpen}
          selectedMark={selectedMark}
          handleClose={handleCloseCompleteAssistanceMark}
          isLoading={isLoading}
        />
      }
    </>

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('ASSISTANCE');

  const customHeader = () => <Typography className="whiteText" style={{ fontSize: "16px", fontWeight: "500" }}>
    {"De todas las marcas de asistencia seleccionadas en la grilla, solo se visualizarán en el mapa las que cuenten con registro de geoposición, por ejemplo las realizadas con la APP de ICARIUS"}
  </Typography>;

  const templatesHeader = (gridRef) => (
    <>
      <div style={{ paddingLeft: 10, display: 'flex' }}>
        <TemplatesHeader
          gridRef={gridRef}
          user={user}
          templates={templates}
          selectedTemplate={selectedTemplate}
          getRole={getRole}
          handleImportTemplates={handleImportTemplates}
          handleExportTemplates={handleExportTemplates}
          handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
          handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
          handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
          handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
        />
      </div>
    </>
  )

  return (
    <CommonPage
      hideGrid={mapIsOpen}
      rowData={rowData}
      title={mapIsOpen ? "Geoposición de marcas" : getLocalizedString("assistanceTitle")}
      gridTitle={getLocalizedString("assistanceTitle")}
      locale={locale}
      dateFormat={dateFormat}
      columnDefPage={paths.assistance}
      isLoading={isLoading || isLoadingTemplates || isExportingGrid}
      hasHelp
      hasSelectAll={!mapIsOpen}
      hasExpand={!mapIsOpen}
      codeFieldName={'CODIGO DE EMPLEADO'}
      menuItems={mapIsOpen ? [goBackItem] : [completAssistanceMarkItem, mapItem]}
      customHeader={mapIsOpen ? customHeader : templatesHeader}
      frameworkComponents={{ PositionImageRenderer, TimeBetweenMarksRenderer }}
      hasSwitch
      handleSwitchClick={() => setshowAllRows(prev => !prev)}
      switchLabelOff={'Ver solo las marcas incompletas'}
      switchLabelOn={'Ver todas las marcas'}
      switchValue={showAllRows}
    >
      {child}
    </CommonPage>
  );
}

export default Assistance;
