import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useHandleTime } from "@icarius-common/clock/useHandleTime";

const Clock = ({ timezone }) => {
  
  const time = useHandleTime();

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        if (txt === "de") return txt;
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  return (
    <Grid style={{ marginTop: 5, marginBottom: 5 }} container direction="column" alignItems="center">
      <Grid item>
        <Typography
          style={{
            fontWeight: 300,
            fontSize: '20px',
            lineHeight: "30px",
            color: 'white',
          }}
          variant="subtitle1"
          align="center"
        >
          {
            time !== "" && toTitleCase(new Date(time).toLocaleDateString("es-ES", {
              timeZone: timezone,
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }))
          }
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: '30px',
            lineHeight: "30px",
            color: 'white',
          }}
        >
          {
            new Date(time).toLocaleTimeString("en-US", {
              timeZone: timezone,
              hour12: false,
            })
          }
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Clock;
