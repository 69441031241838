import {
  GET_ASSISTANCE_ROWS,
  GET_ASSISTANCE_ROWS_FULFILLED,
  GET_ASSISTANCE_ROWS_REJECTED,
  GET_MAP_DATA,
  GET_MAP_DATA_FULFILLED,
  GET_MAP_DATA_REJECTED,
  COMPLETE_ASSISTANCE_MARK,
  COMPLETE_ASSISTANCE_MARK_FULFILLED,
  COMPLETE_ASSISTANCE_MARK_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getAssistance, getMarksForMapAPI, completeAssistanceMark } from "@icarius-connection/api";

export const getAssistanceTableRows = () => async (dispatch) => {
  dispatch({ type: GET_ASSISTANCE_ROWS });
  try {
    let response = await getAssistance();
    let assistance = response.data && response.data.data;
    assistance = assistance.map(el => ({ ...el, hasGPSCoordinates: el.hasGPSCoordinates ? "Si" : "No" }));
    dispatch({
      type: GET_ASSISTANCE_ROWS_FULFILLED,
      payload: {
        assistanceRows: assistance,
        dateFormat: response.data.date_format,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_ASSISTANCE_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const completeAssistanceMarkAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: COMPLETE_ASSISTANCE_MARK });
  try {
    let response = await completeAssistanceMark(dataToSend);

    let assistance = response.data && response.data.data;
    assistance = assistance.map(el => ({ ...el, hasGPSCoordinates: el.hasGPSCoordinates ? "Si" : "No" }));

    dispatch({
      type: COMPLETE_ASSISTANCE_MARK_FULFILLED,
      payload: {
        assistanceRows: assistance,
      },
    });

    dispatch(openSnackbarAction({ msg: "Marca de asistencia completada", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: COMPLETE_ASSISTANCE_MARK_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getMarksForMap = (requestData) => async (dispatch) => {
  dispatch({ type: GET_MAP_DATA });
  try {
    let response = await getMarksForMapAPI({ requestData });
    let data = response.data && response.data.data;

    dispatch({
      type: GET_MAP_DATA_FULFILLED,
      payload: {
        data
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_MAP_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

